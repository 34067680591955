
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import { passwordPolicy } from '@/utils/constants'

import EyeIcon from '@/static/svg/prescription/eye-icon.svg'
import EyeIconOff from '@/static/svg/prescription/eye-icon-off.svg'
import CloseIcon from '@/static/svg/nav/close-icon.svg'
import DropdownSelector from '@/components/DropdownSelector'
import InfoTooltip from '@/components/InfoTooltip'
import PasswordMeter from '@/components/PasswordMeter'
import PasswordTooltip from '@/components/PasswordTooltip'

const loadOptions = async (locale) => {
  const zxcvbnCommonPackage = await import(/* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common')
  let zxcvbnAdditionalLangPackage = []
  let forbiddenWordsLocale = []
  switch (locale) {
    case 'de-DE':
      forbiddenWordsLocale = passwordPolicy.forbiddenWordsLocale['de-DE']
      zxcvbnAdditionalLangPackage = await import(/* webpackChunkName: "zxcvbnDePackage" */ '@zxcvbn-ts/language-de')
      break
    case 'it-IT':
      forbiddenWordsLocale = passwordPolicy.forbiddenWordsLocale['it-IT']
      zxcvbnAdditionalLangPackage = await import(/* webpackChunkName: "zxcvbnItPackage" */ '@zxcvbn-ts/language-it')
      break
    case 'fr-FR':
      forbiddenWordsLocale = passwordPolicy.forbiddenWordsLocale['fr-FR']
      zxcvbnAdditionalLangPackage = await import(/* webpackChunkName: "zxcvbnFrPackage" */ '@zxcvbn-ts/language-fr')
      break
    case 'es-ES':
      forbiddenWordsLocale = passwordPolicy.forbiddenWordsLocale['es-ES']
      zxcvbnAdditionalLangPackage = await import(
        /* webpackChunkName: "zxcvbnEsEsPackage" */ '@zxcvbn-ts/language-es-es'
      )
      break
    case 'en-GB':
    default:
      forbiddenWordsLocale = passwordPolicy.forbiddenWordsLocale['en-GB']
      zxcvbnAdditionalLangPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en')
      break
  }

  return {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnAdditionalLangPackage.dictionary,
      userInputs: [...passwordPolicy.forbiddenWordsUniversal, ...forbiddenWordsLocale],
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
  }
}

export default {
  components: {
    CloseIcon,
    InfoTooltip,
    DropdownSelector,
    PasswordMeter,
    PasswordTooltip,
    EyeIcon,
    EyeIconOff,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: 'Please check value',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    errorEmpty: {
      type: String,
      default: 'Please enter value',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    highlightInput: {
      type: Boolean,
      default: false,
    },
    showRequired: {
      type: Boolean,
      default: false,
    },
    lockedIn: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Boolean],
      default: false,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    usePasswordMeter: {
      type: Boolean,
      default: false,
    },
    emailValue: {
      type: String,
      default: '',
    },
    showPasswordTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      showPassword: false,
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    inputType() {
      if (this.type === 'password' && this.showPassword) {
        return 'text'
      }
      return this.type
    },
    isInputEmpty() {
      return !this.value?.length
    },
    passwordStrength() {
      if (!this.usePasswordMeter || !this.inputValue) {
        return 0
      }
      const result = zxcvbn(this.inputValue, [this.emailValue])
      return result.score
    },
    showPasswordMeter() {
      return this.usePasswordMeter && !this.isInputEmpty
    },
    pattern() {
      return this.usePasswordMeter ? passwordPolicy.pattern : null
    },
    hasTooltip() {
      return !!this.tooltipText || this.showPasswordTooltip
    },
    eyeIconType() {
      return this.showPassword ? 'EyeIconOff' : 'EyeIcon'
    },
  },
  watch: {
    passwordStrength(newStrength) {
      this.$emit('strength-changed', newStrength)
    },
  },
  async mounted() {
    if (!this.usePasswordMeter) {
      return
    }

    const options = await loadOptions(this.$i18n.locale)
    zxcvbnOptions.setOptions(options)
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    clearInput() {
      this.inputValue = ''
      this.$refs.inputField.focus()
    },
    submit() {
      this.isFocused = false
      this.$emit('submit')
    },
    focus() {
      this.isFocused = true
      this.$refs.inputField.focus()
    },
    blur() {
      setTimeout(() => (this.isFocused = false), 200)
      this.$emit('value-changed', this.name)
    },
    changeClicked() {
      this.$emit('change-clicked')
    },
  },
}
