
import ClickOutside from 'vue-click-outside'
import InfoIcon from '@/static/svg/prescription/info-icon.svg'

export default {
  components: {
    InfoIcon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'bottom-right',
    },
    link: {
      type: String,
      default: '',
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  computed: {
    isTop() {
      return this.position.includes('top')
    },
    isBottom() {
      return this.position.includes('bottom')
    },
    isMiddle() {
      return this.position.includes('middle')
    },
    isRight() {
      return this.position.includes('right')
    },
  },
  mounted() {
    this.popupItem = this.$el
  },
  methods: {
    open() {
      this.isOpened = true
      window.addEventListener('scroll', this.close)
    },
    close() {
      this.isOpened = false
      window.removeEventListener('scroll', this.close)
    },
  },
}
